<template>
  <div class="text-left section-title mb--50">
    <span class="subtitle">{{ $t('letsTalk') }}</span>
    <h2 class="heading-title">{{ $t('contactUs') }}</h2>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-row align="start" class="row--35">
          <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
            <div class="form-wrapper">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  <input
                    type="text"
                    v-model="formData.name"
                    :placeholder="$t('yourName')"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <label>
                  <input
                    type="text"
                    rules="required|email"
                    v-model="formData.email"
                    :placeholder="$t('yourEmail')"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>

              <ValidationProvider
                name="subject"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  <input
                    type="text"
                    v-model="formData.subject"
                    :placeholder="$t('subject')"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>
              <button class="btn-default" type="submit" value="submit">
                {{ $t('send') }}
              </button>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
            <div class="mb_md--40 mb_sm--40">
              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  <textarea
                    rows="4"
                    v-model="formData.message"
                    :placeholder="$t('yourMessage')"
                  ></textarea>
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>
            </div>
            <!-- <div class="thumbnail mb_md--40 mb_sm--40">
            <slot name="contact-img"></slot>
          </div> -->
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import axios from "axios";
import swal from "sweetalert";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    onSubmit() {
      const formData = new FormData();
      formData.append("service_id", "gmail");
      formData.append("template_id", "template_844zxFSf");
      formData.append("user_id", "user_cUYogX8Ok2yh0OOsC0Adf");
      formData.append("nomeCompleto", this.formData.name);
      formData.append("email", this.formData.email);
      formData.append("assunto", this.formData.subject);
      formData.append("message", this.formData.message);

      axios({
        method: "post",
        url: "https://api.emailjs.com/api/v1.0/email/send-form",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          swal(
            "Sucesso!",
            "Em até 24 horas entraremos em contato. Obrigado!",
            "success"
          );
          this.resetForm();
        })
        .catch((error) => {
          console.error("Failed to send message", error);
          alert("Oops... " + error.message);
        });
    },
    resetForm() {
      this.formData.name = "";
      this.formData.email = "";
      this.formData.subject = "";
      this.formData.message = "";
    },
  },
};
</script>

<style lang="scss">
.form-wrapper label input,
.form-wrapper label textarea {
  margin-bottom: 0;
}
.form-wrapper label {
  margin-bottom: 20px;
}
</style>
